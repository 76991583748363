import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Form, Tooltip, Icons, Buttons, Toast } from '@/library/components';
import { DisplayResult } from './display-result.components';
import { RefRangesExpandList } from './ref-ranges-expand-list.component';
import { useStores } from '@/stores';
import { FaUserInjured, FaComment, FaCommentAlt } from 'react-icons/fa';
import { PiTestTubeFill, PiStethoscopeBold } from 'react-icons/pi';
import { ImAttachment } from 'react-icons/im';
import {
  getResultStatus,
  getTestStatus,
  getAbnFlag,
  getCretical,
} from '../../../utils';
import { tabs } from '../../../screens';
import { ModalConfirm } from 'react-restyle-components';
import { ModalReferenceRange } from '../reference-ranges/modal-refernce-ranges.component';
import { toJS } from 'mobx';

interface GeneralResultEntryListProps {
  data: any;
  totalSize: number;
  selectedId?: string;
  tabSelected: tabs;
  isView?: boolean;
  isDelete?: boolean;
  isUpdate?: boolean;
  isExport?: boolean;
  onUpdateValue: (item: any, id: string) => void;
  onResultUpdateBatch: (records: any) => void;
  onUpdateFields?: (fields: any, id: string) => void;
  onPageSizeChange?: (page: number, totalSize: number) => void;
  onFinishResult?: (updateRecordIds: Array<string>) => void;
  onFilter?: (
    type: string,
    filter: any,
    page: number,
    totalSize: number,
  ) => void;
  onTabSelected: (type: tabs) => void;
  onFilterFinishResult?: (code: string) => void;
  onTestStatusFilter?: (code: string) => void;
  onExpand?: (items: any) => void;
  onTableReload?: () => void;
  onReportView: (item: any) => void;
  selectedRowData?: any;
}

export const GeneralResultEntryList = ({
  data,
  isView,
  onFilterFinishResult,
  onResultUpdateBatch,
  onFinishResult,
  tabSelected,
  onTabSelected,
  onReportView,
}: GeneralResultEntryListProps) => {
  const { appStore } = useStores();
  const resultRecords = useRef<Array<any>>([]);
  const [refRangeModal, setRefRangeModal] = useState({});
  const [selectedRowId, setSelectedRowId] = useState<string>('');
  const [isHide, setIsHide] = useState<boolean>(false);
  const arrFinishRecord = useRef<Array<string>>([]);
  const [reload, setReload] = useState(false);
  const [modalConfirm, setModalConfirm] = useState<any>();

  const distinctRecords = data?.map(item => ({
    ...item?.result[0],
    count: item?.count,
  }));

  useEffect(() => {
    setIsHide(false);
    resultRecords.current = [];
  }, [data]);

  const statusData = [
    {
      code: 'P',
      value: 'Pending',
      color: 'blue',
      disable: false,
      selected: true,
    },
    // { code: 'RC', value: 'Recheck', color: 'orange', disable: true },
    // { code: 'RT', value: 'Retest', color: 'pink', disable: true },
    {
      code: 'D',
      value: 'Done',
      color: 'green',
      disable: false,
      selected: false,
    },
    // { code: '', value: 'All', color: 'red', disable: false },
  ];

  // const testStatus = [
  //   { code: 'N', value: 'Normal', color: 'blue' },
  //   { code: 'A', value: 'Abnormal', color: 'yellow' },
  //   { code: 'C', value: 'Critical', color: 'green' },
  // ];

  const truncateText = (text: string, maxLength: number) =>
    text?.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  const handleRowClick = (index: number) => {
    // resultRecords.current = props.data[index]?.result;
    // console.log({
    //   data: data[index]?.result?.sort((a, b) =>
    //     (a?.resultOrder || '') > (b?.resultOrder || '') ? 1 : -1,
    //   ),
    // });
    resultRecords.current = data[index]?.result?.sort((a, b) =>
      (a?.resultOrder || '') > (b?.resultOrder || '') ? 1 : -1,
    );
    setIsHide(true);
  };

  const expandCollapseButton = () => (
    <div className='ml-2'>
      <Buttons.Button
        size='medium'
        type='outline'
        onClick={() => {
          if (tabSelected == 'Pending') {
            setModalConfirm({
              visible: true,
              type: 'confirm',
              title: 'Are you sure?',
              message: 'Do you want to exist without save result?',
              submitTitle: 'Yes',
              closeTitle: 'No',
            });
          } else {
            setIsHide(false);
            resultRecords.current = [];
          }
        }}
      >
        <Tooltip
          tooltipText={
            appStore.applicationSetting?.isExpandScreen
              ? 'Collapse Screen'
              : 'Expand Screen'
          }
        >
          <Icons.IconContext
            color={
              appStore.applicationSetting.theme === 'dark'
                ? '#ffffff'
                : '#000000'
            }
            size='18'
          >
            {Icons.getIconTag(Icons.IconCg.CgMinimize)}
          </Icons.IconContext>
        </Tooltip>
      </Buttons.Button>
    </div>
  );

  const renderStatusButtons = () => (
    <div className='flex gap-2 items-center'>
      {/* {statusData.map(status => (
        <button
          key={status.code}
          disabled={status.disable}
          className={`bg-${status.color}-600 ml-2 px-3 py-2 focus:outline-none items-center outline shadow-sm font-medium text-center rounded-md text-white disabled:opacity-50 disabled:cursor-not-allowed`}
        >
          {status.value}
        </button>
      ))} */}
      <ul className='flex flex-wrap text-sm font-medium text-center cursor-pointer gap-1'>
        {statusData.map((item, index) => (
          <li key={index}>
            <div
              className={`inline-flex items-center justify-center p-2 border-b-2 gap-1 ${
                item.value === tabSelected
                  ? 'dark:border-white active border-blue-800 text-blue-800'
                  : 'border-transparent hover:text-[#27A4FE] hover:border-gray-300 '
              }   `}
              onClick={() => {
                onTabSelected(item.value as tabs);
                onFilterFinishResult && onFilterFinishResult(item.code);
                setIsHide(false);
                resultRecords.current = [];
              }}
            >
              <span>{item.value}</span>
            </div>
          </li>
        ))}
      </ul>
      {isHide && expandCollapseButton()}
      <span className='flex bg-blue-800 w-8 h-8 rounded-full text-white items-center text-center justify-center'>
        {data?.length || 0}
      </span>
    </div>
  );

  const rowStyle = row => {
    switch (row?.colorScheme?.envRangeColor) {
      case 'BOTH':
        return {
          backgroundColor: row?.colorScheme?.cellColor,
          color: row?.colorScheme?.fontColor,
        };
        break;
      case 'BACKGROUND':
        return {
          backgroundColor: row?.colorScheme?.cellColor,
        };
        break;
      case 'FONT':
        return {
          color: row?.colorScheme?.fontColor,
        };
        break;
      default:
        return {};
    }
  };

  // console.log({ data: resultRecords.current });

  const renderResultEnter = () =>
    resultRecords.current?.length > 0 && (
      <div>
        <div className='shadow-lg rounded-lg border border-gray-200'>
          <div className='overflow-x-auto max-h-[51vh]'>
            <div className='flex '>{renderFixedColumns()}</div>
          </div>
        </div>
        <div className='flex justify-start gap-2 mt-1'>
          {tabSelected === 'Pending' && renderSaveButton()}
          {renderFooterIcons()}
        </div>
      </div>
    );

  const renderFixedColumns = () => (
    <>
      {[
        {
          title: 'Analyte Code - Name',
          width: '280px',
          content: record => `${record.analyteCode} - ${record.analyteName}`,
          editable: false,
        },
        {
          title: 'Reportable',
          width: '100px',
          content: record => (
            <Form.Toggle
              value={record.reportable}
              onChange={reportable => updateRecord(record._id, { reportable })}
            />
          ),
        },
        {
          title: 'Result',
          width: '100px',
          content: (record, subIndex) => renderResultColumn(record, subIndex),
        },
        {
          title: 'Conclusion',
          width: '150px',
          content: record => renderConclusionColumn(record),
        },
        { title: 'Units', width: '100px', content: record => record.units },
        {
          title: 'Range',
          width: '200px',
          content: record => renderRangeColumn(record),
        },
        {
          title: 'Abnormal',
          width: '135px',
          content: record => (
            <Form.Toggle
              disabled={true}
              value={record.critical ? true : record.abnFlag}
            />
          ),
        },
        {
          title: 'Critical',
          width: '135px',
          content: record => (
            <Form.Toggle disabled={true} value={record.critical} />
          ),
        },
        {
          title: 'Calculation Flag',
          width: '135px',
          content: record => (
            <Form.Toggle disabled value={record.calculationFlag} />
          ),
        },
        {
          title: 'Show Ranges',
          width: '135px',
          content: record => (
            <Form.Toggle
              value={record.showRanges}
              onChange={showRanges => updateRecord(record._id, { showRanges })}
            />
          ),
        },
        {
          title: 'Result Status',
          width: '100px',
          content: record => record.resultStatus,
        },
        {
          title: 'Result Type',
          width: '100px',
          content: record => record.resultType,
        },
      ].map(({ title, width, content }: any, index) => (
        <div className='flex flex-col' key={index}>
          <div
            className='text-white py-1 px-1 text-center sticky top-0 z-1'
            style={{ width, backgroundColor: '#6A727F' }}
          >
            {title}
          </div>
          {resultRecords.current?.map((record: any, subIndex) => (
            <div
              key={subIndex}
              className='text-gray-700 border-b text-sm cursor-pointer'
              style={{
                width,
                backgroundColor: '#fff',
                padding: '2px',
                display: 'flex',
                justifyContent:
                  title === 'Analyte Code - Name' ? 'start' : 'center',
                alignItems: 'center',
                height: '50px',
                ...rowStyle(record),
              }}
            >
              <span
                title={
                  typeof content(record, subIndex) === 'string'
                    ? content(record, subIndex)
                    : undefined
                }
              >
                {typeof content(record, subIndex) === 'string'
                  ? truncateText(content(record, subIndex), 50)
                  : content(record, subIndex)}
              </span>
            </div>
          ))}
        </div>
      ))}
    </>
  );

  const splitMulti = (str, tokens) => {
    // eslint-disable-next-line
    // for (let i = 0; i < tokens.length; i++) {
    //   str = str.split(tokens[i]).join(`#${tokens[i]}`);
    // }
    // str = str.split('#');
    return str;
  };

  console.log({ records: resultRecords.current });
  const renderResultColumn = (record, index) =>
    record.isResultEditor ? (
      <>
        <DisplayResult
          row={record}
          rowIndex={index}
          isDisable={tabSelected == 'Pending' ? false : true}
          rowData={toJS(resultRecords.current)}
          onSelect={result => {
            /*eslint-disable*/
            updateRecord(record._id, { result });
            //let finalResult = result;
            // console.log({ result });
            if (
              resultRecords.current?.filter(item => item?.calculationFlag)
                ?.length > 0
            ) {
              // calculation records update
              const resultsCalFlagFalse = resultRecords.current
                ?.map(item => {
                  if (item?.calculationFlag)
                    return {
                      _id: item?._id,
                      calculationFormula: item?.calculationFormula,
                    };
                })
                ?.filter(item => item);
              // console.log({ resultsCalFlagFalse });
              resultsCalFlagFalse?.map(calItem => {
                let splitFormula: any = calItem?.calculationFormula
                  .split('=')
                  .pop();
                splitFormula = splitFormula?.split('');
                let fullFormula = splitFormula?.join('');
                // console.log({ splitFormula, beforeFormula: fullFormula });
                const regExp = /[a-zA-Z]/g;
                const regExp1 = /[^a-zA-Z]+/g;
                splitFormula.map(fl => {
                  if (regExp.test(fl)) {
                    fullFormula = fullFormula?.replace(
                      fl?.replace(regExp1, ''),
                      resultRecords.current?.find(
                        val =>
                          val?.calculationVariable === fl?.replace(regExp1, ''),
                      )?.result?.result || '0',
                    );
                  }
                });
                // console.log({
                //   fullFormula,
                //   result: regExp.test(fullFormula)
                //     ? 0
                //     : Number.parseFloat(eval(fullFormula).toString()).toFixed(
                //         record?.picture || 0,
                //       ),
                // });
                updateRecord(calItem?._id, {
                  result: {
                    result: Number.parseFloat(
                      eval(fullFormula).toString(),
                    ).toFixed(record?.picture || 0),
                    numeric: eval(fullFormula).toString(),
                  },
                  testStatus: 'N',
                  resultStatus: 'N',
                });
              });
            }
            // else {
            //   updateRecord(record._id, { result });
            // }
          }}
        />
      </>
    ) : (
      <span className='flex text-black' style={{ fontWeight: 'bold' }}>
        {typeof record?.result == 'object'
          ? record?.result?.result?.toString()
          : record?.result?.toString() || ''}
      </span>
    );

  const renderConclusionColumn = record => (
    <div className='flex flex-col items-center justify-center'>
      <Tooltip
        tooltipText={record._id !== selectedRowId ? 'Expand' : 'Collapse'}
      >
        <Icons.IconContext
          color='#000000'
          size='20'
          onClick={() =>
            setSelectedRowId(record._id === selectedRowId ? '' : record._id)
          }
        >
          {Icons.getIconTag(
            record._id !== selectedRowId
              ? Icons.IconBi.BiExpand
              : Icons.IconBi.BiCollapse,
          )}
        </Icons.IconContext>
      </Tooltip>
      {record._id === selectedRowId && (
        <Form.MultilineInput
          rows={1}
          placeholder='Conclusion'
          className='text-black'
          defaultValue={record?.conclusion}
          onBlur={conclusion => updateRecord(record._id, { conclusion })}
        />
      )}
    </div>
  );

  const renderRangeColumn = record => (
    <div className='flex flex-col items-center justify-center gap-2'>
      <div className='flex items-center min-w-[130px] justify-between flex-row gap-2'>
        <span className='pt-[6px]' title={record.units}>
          {renderRangeValue(record)}
        </span>
        {record.refRangesList?.length > 0 && (
          <Tooltip tooltipText={'View Reference Range List'}>
            <Icons.IconContext
              color='#000000'
              size='20'
              onClick={() =>
                setRefRangeModal({
                  ...refRangeModal,
                  show: true,
                  data: record?.refRangesList,
                })
              }
            >
              {Icons.getIconTag(Icons.IconBi.BiExpand)}
            </Icons.IconContext>
          </Tooltip>
        )}
      </div>
    </div>
  );

  const renderRangeValue = record => {
    const { loNor, hiNor } = record;
    return _.isNaN(Number.parseFloat(loNor)) &&
      _.isNaN(Number.parseFloat(hiNor))
      ? '-'
      : _.isNaN(Number.parseFloat(loNor))
      ? `${hiNor} - >`
      : _.isNaN(Number.parseFloat(hiNor))
      ? `< - ${loNor}`
      : `${loNor} - ${hiNor}`;
  };

  const renderSaveButton = () => (
    <button
      className='py-2 mt-1 w-24 focus:outline-none bg-blue-600 items-center outline shadow-sm font-medium text-center rounded-md text-white disabled:opacity-50 disabled:cursor-not-allowed'
      onClick={handleSaveClick}
    >
      Save
    </button>
  );

  const handleSaveClick = () => {
    // console.log({
    //   flag: resultRecords.current?.every(item => {
    //     if (item?.calculationFlag) return true;
    //     else if (item?.result && !_.isEmpty(item.result)) return true;
    //     else return false;
    //   }),
    // });
    let isSaveRecords = true;
    if (
      resultRecords.current?.every(item => {
        if (item?.calculationFlag) return true;
        else if (item?.result && !_.isEmpty(item.result)) return true;
        else return false;
      })
    ) {
      //console.log({ record: resultRecords.current });
      resultRecords.current.map(item => {
        const result =
          typeof item?.result == 'object' ? item.result?.result : item.result;
        item?.refRangesList?.map(refRang => {
          if (refRang?.rangeType == 'Absurd Value') {
            //eslint-disable-next-line
            if (
              Number.parseFloat(result) < Number.parseFloat(refRang?.low) ||
              Number.parseFloat(result) > Number.parseFloat(refRang?.high)
            ) {
              if (refRang?.alpha?.split(' - ')[0] == 'H') {
                if (isSaveRecords)
                  Toast.error({
                    message: refRang?.alpha?.split(' - ')[1] || '',
                    timer: 2000,
                  });
                isSaveRecords = false;
              } else
                Toast.warning({
                  message: refRang?.alpha?.split(' - ')[1] || '',
                  timer: 2000,
                });
            }
          }
        });
      });
      if (isSaveRecords) {
        const records = resultRecords.current.map(item => ({
          ...item,
          resultStatus: getResultStatus(item.resultType, item),
          testStatus: getTestStatus(item.resultType, item),
          abnFlag: getAbnFlag(item.resultType, item),
          critical: getCretical(item.resultType, item),
          updateField: 'result',
          updateType: 'directSave',
          resultDate: new Date(),
        }));
        onResultUpdateBatch(records);
        setIsHide(false);
        resultRecords.current = [];
      }
    } else {
      Toast.error({ message: 'Please enter all result.' });
    }
  };

  const renderFooterIcons = () => (
    <div
      className='flex w-100 p-2 flex-row justify-center gap-4 items-center'
      style={{ backgroundColor: '#6A727F' }}
    >
      {renderFooterIcon(PiStethoscopeBold, 'Doctor')}
      {renderFooterIcon(FaUserInjured, 'Patient')}
      {renderFooterIcon(PiTestTubeFill, 'Sample Information')}
      {renderFooterIcon(ImAttachment, 'Attachment')}
      {renderFooterIcon(FaComment, 'Internal Comment')}
      {renderFooterIcon(FaCommentAlt, 'External Comment')}
    </div>
  );

  const renderFooterIcon = (Icon, tooltip) => (
    <Tooltip tooltipText={tooltip}>
      <Icon color='#ffffff' size={'30'} />
    </Tooltip>
  );

  const updateRecord = (id, updatedFields) => {
    // console.log({ id, updatedFields });
    resultRecords.current = resultRecords.current.map(item =>
      item._id === id ? { ...item, ...updatedFields } : item,
    );
    setReload(!reload);
    return true;
  };

  const renderDataRows = () => (
    <div
      className={`sticky top-0 text-white border-white ${
        isHide ? 'hidden' : 'shown'
      } bg-[#6A727F]`}
    >
      <div
        className={`flex justify-around items-center py-2 sticky top-0 bg-[#6A727F] ${
          tabSelected === 'Done' ? 'ml-4' : 'ml-0'
        }`}
      >
        {renderDataHeader()}
      </div>
      {distinctRecords.map((record, index) => renderDataRow(record, index))}
      {tabSelected === 'Done' && renderSubmitButton()}
    </div>
  );

  const renderDataHeader = () => (
    <>
      {[
        'Panel Code',
        'Test Code - Name',
        'Department',
        'Lab ID',
        'Sample ID',
        'Patient Name',
        'Lab',
        'Due Date',
        'Result Date',
        'Test S.',
      ].map((title, index) => (
        <div
          key={index}
          className={'flex text-center sticky top-0 z-10'}
          style={{ width: getColumnWidth(index) }}
        >
          {title}
        </div>
      ))}
    </>
  );

  const renderDataRow = (record, index) => (
    <div
      className={`flex ${
        index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
      } cursor-pointer ${isHide ? 'hidden' : 'shown'}`}
      key={record._id}
      onClick={(event: any) => {
        if (event.target.type !== 'checkbox') {
          handleRowClick(index);
        }
      }}
    >
      {tabSelected === 'Done' && renderCheckbox(record, index)}
      <div className='flex w-full justify-around items-center py-2 border-b text-sm'>
        {renderDataRowContent(record)}
      </div>
    </div>
  );

  const renderCheckbox = (record, index) => (
    <div className='flex bg-[#6A727F] p-1 items-center justify-center gap-1'>
      <input
        className='flex'
        type='checkbox'
        checked={arrFinishRecord.current?.includes(record._id)}
        onClick={() => handleCheckboxClick(record, index)}
      />
      <Tooltip tooltipText='Report View'>
        <Icons.IconContext
          color='#ffffff'
          size='20'
          onClick={() => {
            onReportView(record);
          }}
        >
          {Icons.getIconTag(Icons.IconBs.BsFilePdf)}
        </Icons.IconContext>
      </Tooltip>
    </div>
  );

  const handleCheckboxClick = (record, index) => {
    const ids = data[index].result?.map(item => item?._id) || [];
    arrFinishRecord.current = arrFinishRecord.current.includes(record._id)
      ? arrFinishRecord.current.filter(item => !ids.includes(item))
      : [...arrFinishRecord.current, ...ids];
    setReload(!reload);
  };

  const renderDataRowContent = record => (
    <>
      {[
        {
          content: () => (
            <div className='flex gap-2 items-center'>
              <span className='flex bg-blue-800 w-6 h-6 rounded-full text-white items-center text-center justify-center'>
                {record?.count}
              </span>
              <span>{truncateText(record.panelCode, 15)}</span>
            </div>
          ),
          width: '150px',
        },
        {
          content: () => (
            <>
              <span>
                {truncateText(`${record.testCode} - ${record.testName}`, 22)}
              </span>
            </>
          ),
          width: '200px',
        },
        {
          content: () => truncateText(record.departmentName, 20),
          width: '150px',
        },
        { content: () => truncateText(record.labId, 10), width: '50px' },
        { content: () => truncateText(record.sampleId, 10), width: '100px' },
        { content: () => truncateText(record.name, 15), width: '150px' },
        { content: () => truncateText(record.pLab, 10), width: '50px' },
        {
          content: () =>
            truncateText(
              record.dueDate &&
                dayjs(record.dueDate).format('DD-MM-YYYY HH:mm:ss'),
              20,
            ),
          width: '100px',
        },
        {
          content: () =>
            truncateText(
              record.resultDate &&
                dayjs(record.resultDate).format('DD-MM-YYYY HH:mm:ss'),
              20,
            ),
          width: '140px',
        },
        { content: () => truncateText(record?.testStatus, 10), width: '50px' },
      ].map(({ content, width }, index) => (
        <div
          key={index}
          className='flex text-center text-gray-700'
          style={{ width }}
        >
          {content()}
        </div>
      ))}
    </>
  );

  const renderSubmitButton = () => (
    <div className='flex p-2'>
      <button
        className='bg-blue-800 rounded-md p-2'
        onClick={handleSubmitClick}
      >
        Submit
      </button>
    </div>
  );

  const handleSubmitClick = () => {
    if (arrFinishRecord.current.length > 0) {
      onFinishResult?.(arrFinishRecord.current);
      // setTabSelected('Pending');
    } else {
      Toast.error({ message: 'Please select any one record' });
    }
  };

  const getColumnWidth = index =>
    [
      '150px',
      '200px',
      '150px',
      '50px',
      '100px',
      '150px',
      '50px',
      '100px',
      '140px',
      '50px',
    ][index];

  return (
    <div className={`${isView ? 'shown' : 'hidden'} `}>
      <div className='flex flex-row flex-wrap justify-between mb-2'>
        {renderStatusButtons()}
      </div>
      <div className='flex flex-col max-h-[calc(100vh_-_40vh)] overflow-y-auto'>
        {renderDataRows()}
        {renderResultEnter()}
      </div>
      <ModalConfirm
        {...modalConfirm}
        onClick={() => {
          setIsHide(false);
          resultRecords.current = [];
          setModalConfirm({ visible: false });
        }}
        onClose={() => {
          setModalConfirm({ visible: false });
        }}
      />
      <ModalReferenceRange
        {...refRangeModal}
        onClose={() => {
          setRefRangeModal({ show: false });
        }}
      />
    </div>
  );
};
